import { createIntl, createIntlCache, IntlShape, FormatNumberOptions, FormatDateOptions } from "react-intl";
import { merge } from "lodash";
import { NullableNumber, NumericValueOptions } from "../NumericInput/Base/Types.ts";
import NumberParser from "../../old/src/components/smartPulse/NumberParser.ts";
import { defaultUserCulture } from "src/contexts/UserCultureContext.tsx";

export default interface UserCultureFormatterProvider {
    currentLocale: string,
    parseToNumber: (value: string) => NullableNumber,
    formatNumericValue: (value: NullableNumber, options?: FormatNumberOptions) => string,
    formatVolumeValue: (value: NullableNumber, isMwh: boolean, options?: FormatNumberOptions) => string,
    formatPriceValue: (value: NullableNumber, options?: FormatNumberOptions) => string,
    formatCurrencyValue: (value: NullableNumber, options?: FormatNumberOptions) => string,
    formatNumberPattern: (value: NullableNumber, pattern: string, options?: FormatNumberPatternOptions) => string,
    getNumberPatternParts: (value: NullableNumber, pattern: string, options?: FormatNumberPatternOptions) => {
        formatted?: string,
        metaOfMask?: PatternFormattingMaskMeta,
        metaOfValue?: PatternFormattingValueMeta,
    },
    formatDate: (value: Date, options: FormatDateOptions) => string,
    formatDateValue: (value: Date, options?: FormatDateOptions) => string,
    formatTimeValue: (value: Date, options?: FormatDateOptions) => string,
    formatDateTimeValue: (value: Date, options?: FormatDateOptions) => string,
    formatDateTimeWithOffset: (value: Date, options?: FormatDateOptions) => string,
}

const replaceMinusSignToHypenMinus = (value: string): string => value.replace("−", "-");

export class UserCultureFormatterService implements UserCultureFormatterProvider {

    private readonly fractionDigitsDefaultByVolume: number = 1;
    private readonly fractionDigitsDefaultByPrice: number = 2;

    private readonly intl: IntlShape;
    private readonly locale: string;
    private readonly numberParser: NumberParser;

    constructor(locale: string, intl?: IntlShape) {
        this.locale = locale;
        this.numberParser = new NumberParser(locale);

        if (!intl) {
            this.intl = createIntl({
                locale: locale,
                defaultLocale: locale
            }, createIntlCache());
        } else {
            this.intl = intl;
        }
    }

    public get currentLocale(): string {
        return this.locale;
    };

    //#region Number

    public parseToNumber = (value: string): NullableNumber => {
        if (!value) {
            return null;
        }
        const asNumber = this.numberParser.parse(value);
        return isNaN(asNumber) ? null : asNumber;
    }

    public formatNumericValue = (value: NullableNumber, options?: FormatNumberOptions): string => {
        if (value === null) {
            return "";
        }
        const defaultOptions: FormatNumberOptions = {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            signDisplay: 'auto',
        };
        const finalOptions = merge(defaultOptions, options);
        const { formatNumber } = this.intl;

        return replaceMinusSignToHypenMinus(formatNumber(value, finalOptions));
    }

    public formatVolumeValue = (value: NullableNumber, isMwh: boolean = false, options?: FormatNumberOptions): string => {
        const defaultOptions: FormatNumberOptions = {
            style: 'decimal',
            minimumFractionDigits: isMwh ? this.fractionDigitsDefaultByVolume : 0,
            maximumFractionDigits: isMwh ? this.fractionDigitsDefaultByVolume : 0,
            signDisplay: 'auto',
        };
        const finalOptions = merge(defaultOptions, options);
        return this.formatNumericValue(value, finalOptions);
    }

    public formatPriceValue = (value: NullableNumber, options?: FormatNumberOptions): string => {
        const defaultOptions: FormatNumberOptions = {
            style: 'decimal',
            minimumFractionDigits: this.fractionDigitsDefaultByPrice,
            maximumFractionDigits: this.fractionDigitsDefaultByPrice,
            signDisplay: 'auto',
        };
        const finalOptions = merge(defaultOptions, options);
        return this.formatNumericValue(value, finalOptions);
    }

    public formatCurrencyValue = (value: NullableNumber, options?: FormatNumberOptions | undefined): string => {
        const defaultOptions: FormatNumberOptions = {
            style: 'currency',
            currency: 'TRY',
            minimumFractionDigits: this.fractionDigitsDefaultByPrice,
            maximumFractionDigits: this.fractionDigitsDefaultByPrice,
            signDisplay: 'auto',
            currencySign: 'standard',
            currencyDisplay: 'narrowSymbol',
        };
        const finalOptions = merge(defaultOptions, options);
        return this.formatNumericValue(value, finalOptions);
    }

    public formatNumberPattern = (value: NullableNumber, pattern: string, options?: FormatNumberPatternOptions): string => {
        if (value === null) {
            return "";
        }
        const { formatted } = this.getNumberPatternParts(value, pattern, options);
        return (formatted ?? '');
    }

    public getNumberPatternParts = (value: NullableNumber, pattern: string, options?: FormatNumberPatternOptions): {
        formatted?: string,
        metaOfMask?: PatternFormattingMaskMeta,
        metaOfValue?: PatternFormattingValueMeta,
    } => {
        if (value === null) {
            return {};
        }
        return formatNumberPattern(pattern, value, options);
    }

    //#endregion

    //#region DateTime

    public formatDate = (value: Date, options: FormatDateOptions): string => {
        const { formatDate, } = this.intl;
        return formatDate(value, options);
    }

    public formatDateValue = (value: Date, options?: FormatDateOptions): string => {
        const { formatDate, } = this.intl;
        const defaultOptions: FormatDateOptions = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        };
        return formatDate(value, merge(defaultOptions, options));
    }

    public formatTimeValue = (value: Date, options?: FormatDateOptions): string => {
        const { formatTime } = this.intl;
        const defaultOptions: FormatDateOptions = {
            hour: '2-digit',
            minute: '2-digit',
            second: undefined,
        };
        return formatTime(value, merge(defaultOptions, options));
    }

    public formatDateTimeValue = (value: Date, options?: FormatDateOptions): string => {
        const { formatDate, } = this.intl;
        const defaultOptions: FormatDateOptions = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: undefined,
        };
        return formatDate(value, merge(defaultOptions, options));
    }

    public formatDateTimeWithOffset = (value: Date, options?: FormatDateOptions | undefined): string => {
        const { formatDate, } = this.intl;
        const defaultOptions: FormatDateOptions = {
            dateStyle: "short",
            timeStyle: "full",
        };

        const formatted = formatDate(value, merge(defaultOptions, options));
        return `${formatted}`.replace("GMT", "(UTC") + ")";
    }

    //#endregion

    //#region statics

    public static getLocaleNumberOptions = (locale: string): NumericValueOptions => {
        const parts = new Intl.NumberFormat(locale).formatToParts(-12345.6);
        return {
            // @ts-ignore
            decimalSeparator: parts.find(d => d.type === "decimal").value,
            // @ts-ignore
            groupSeparator: parts.find(d => d.type === "group").value,
            // @ts-ignore
            // minusNumberSign: parts.find(d => d.type === "minusSign").value,
            minusNumberSign: "-"
        }
    }

    public static getValidNumberFormatLocale = (currentLocale: string): string => {
        if (!currentLocale || currentLocale === "" || !Intl.NumberFormat.supportedLocalesOf([currentLocale]).length) {
            console.error(`Missing locale data for locale ${currentLocale}. Using default locale: ${defaultUserCulture} as fallback`);
            return defaultUserCulture;
        }
        return currentLocale;
    }

    //#endregion
}

export type FormatNumberPatternOptions = {
    enforceMaskSign?: boolean;
    cultureLocale?: string;
    prefix?: string,
    suffix?: string,
};

// Original javascript repo: https://github.com/Mottie/javascript-number-formatter
export const formatNumberPattern = (mask: string, value: number, options: FormatNumberPatternOptions = {}): {
    formatted?: string,
    metaOfMask?: PatternFormattingMaskMeta,
    metaOfValue?: PatternFormattingValueMeta,
} => {
    const maskRegex = /[0-9\-+#]/;
    const notMaskRegex = /[^\d\-+#]/g;

    function getIndex(mask: string): number {
        return mask.search(maskRegex);
    }

    function processMask(mask = "#.##"): PatternFormattingMaskMeta {
        const maskObj = {} as PatternFormattingMaskMeta;
        const len = mask.length;
        const start = getIndex(mask);
        maskObj.prefix = start > 0 ? mask.substring(0, start) : "";

        // Reverse string: not an ideal method if there are surrogate pairs
        const end = getIndex(mask.split("").reverse().join(""));
        const offset = len - end;
        const substr = mask.substring(offset, offset + 1);
        // Add 1 to offset if mask has a trailing decimal/comma
        const indx = offset + ((substr === "." || (substr === ",")) ? 1 : 0);
        maskObj.suffix = end > 0 ? mask.substring(indx, len) : "";

        maskObj.mask = mask.substring(start, indx);
        maskObj.maskHasNegativeSign = maskObj.mask.charAt(0) === "-";
        maskObj.maskHasPositiveSign = maskObj.mask.charAt(0) === "+";

        // Search for group separator & decimal; anything not digit,
        // not +/- sign, and not #
        const matchResult = maskObj.mask.match(notMaskRegex);
        // Treat the right most symbol as decimal
        maskObj.decimal = (matchResult && matchResult[matchResult.length - 1]) || ".";
        // Treat the left most symbol as group separator
        maskObj.separator = (matchResult && matchResult[1] && matchResult[0]) || ",";

        // Split the decimal for the format string if any
        const splitResult = maskObj.mask.split(maskObj.decimal);
        maskObj.integer = splitResult[0];
        maskObj.fraction = splitResult[1];

        return maskObj;
    }

    function processValue(
        value: number,
        maskObj: PatternFormattingMaskMeta,
        options: FormatNumberPatternOptions): PatternFormattingValueMeta {

        let isNegative = false;
        //@ts-ignores
        const valObj: PatternFormattingValueMeta = {
            value
        };
        if (value < 0) {
            isNegative = true;
            // Process only abs(), and turn on flag.
            valObj.value = -valObj.value;
        }

        valObj.sign = isNegative ? "-" : "";

        // Fix the decimal first, toFixed will auto fill trailing zero.
        let valueAsString: string = valObj.value.toFixed(maskObj.fraction.length);
        // Convert number to string to trim off *all* trailing decimal zero(es)
        valueAsString = Number(valueAsString).toString();

        valObj.value = Number(valueAsString);

        // Fill back any trailing zero according to format
        // look for last zero in format
        const posTrailZero = (maskObj.fraction ?? '').lastIndexOf("0");
        let [valInteger = "0", valFraction = ""] = valObj.value.toString().split(".");

        if (!valFraction || (valFraction && valFraction.length <= posTrailZero)) {
            valFraction = posTrailZero < 0
                ? ""
                : (Number("0." + valFraction).toFixed(posTrailZero + 1)).replace("0.", "");
        }

        valObj.integer = valInteger;
        valObj.fraction = valFraction;

        addSeparators(valObj, maskObj);

        // Remove negative sign if result is zero
        if (valObj.result === "0" || valObj.result === "") {
            // Remove negative sign if result is zero
            isNegative = false;
            valObj.sign = "";
        }

        if (!isNegative && maskObj.maskHasPositiveSign) {
            valObj.sign = "+";
        } else if (isNegative && maskObj.maskHasPositiveSign) {
            valObj.sign = "-";
        } else if (isNegative) {
            valObj.sign = options && options.enforceMaskSign && !maskObj.maskHasNegativeSign
                ? ""
                : "-";
        }

        return valObj;
    }

    function addSeparators(valObj: PatternFormattingValueMeta, maskObj: PatternFormattingMaskMeta): PatternFormattingValueMeta {
        valObj.result = "";
        // Look for separator
        const szSep = maskObj.integer.split(maskObj.separator);
        // Join back without separator for counting the pos of any leading 0
        const maskInteger = szSep.join("");

        const posLeadZero = (maskInteger ?? '').indexOf("0");

        if (posLeadZero > -1) {
            while (valObj.integer.length < (maskInteger.length - posLeadZero)) {
                valObj.integer = "0" + valObj.integer;
            }
        } else if (Number(valObj.integer) === 0) {
            valObj.integer = "";
        }

        // Process the first group separator from decimal (.) only, the rest ignore.
        // get the length of the last slice of split result.
        const posSeparator = (szSep[1] && szSep[szSep.length - 1].length);

        if (posSeparator) {
            const len = valObj.integer.length;
            const offset = len % posSeparator;

            for (let indx = 0; indx < len; indx++) {
                valObj.result += valObj.integer.charAt(indx);
                // -posSeparator so that won't trail separator on full length
                if (!((indx - offset + 1) % posSeparator) && indx < len - posSeparator) {
                    valObj.result += maskObj.separator;
                }
            }
        } else {
            valObj.result = valObj.integer;
        }

        valObj.result += (maskObj.fraction && valObj.fraction)
            ? maskObj.decimal + valObj.fraction
            : "";
        return valObj;
    }

    if (!mask || (!value && value !== 0) || isNaN(value)) {
        // Invalid inputs
        return {};
    }

    const { prefix, suffix, cultureLocale } = options;
    const maskObj = processMask(mask);
    const valObj = processValue(value, maskObj, options);

    let formattedResult: string = valObj.result;
    let minusSign: string = valObj.sign ?? "";

    if (cultureLocale && cultureLocale.length > 0 && formattedResult.length > 0) {
        const options = UserCultureFormatterService.getLocaleNumberOptions(cultureLocale);
        const { decimalSeparator, groupSeparator, minusNumberSign } = options;

        formattedResult = formattedResult
            .replaceAll(maskObj.separator, '__rt__')
            .replaceAll(maskObj.decimal, decimalSeparator)
            .replaceAll('__rt__', groupSeparator);

        minusSign = minusSign.length > 0 ? minusNumberSign : "";
    }

    return {
        formatted: (prefix ?? maskObj.prefix) + minusSign + formattedResult + (suffix ?? maskObj.suffix),
        metaOfMask: { ...maskObj },
        metaOfValue: { ...valObj }
    };
}

type PatternFormattingMaskMeta = {
    decimal: string,
    fraction: string,
    integer: string,
    mask: string,
    maskHasPositiveSign: boolean,
    maskHasNegativeSign: boolean,
    prefix: string,
    suffix: string,
    separator: string,
}

type PatternFormattingValueMeta = {
    value: number,
    result: string,
    fraction: string,
    integer: string,
    sign: string,
}
