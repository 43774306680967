import { IMyProposalEventData } from "../../redux/api/types/gipPlanningWebSocketTypes";
import BaseEventBus, { IntradayEvent, ISmartPulseEventBus } from "../BaseEventBus";
import EventBusEnum from "../EventBusEnum";

const depthEventBusContainer = new Map<string, MyProposalEventBus>();
export const getOrCreateMyProposalEventBus = (contractDataKey: string): MyProposalEventBus => {
   if (depthEventBusContainer.has(contractDataKey)) {
      return depthEventBusContainer.get(contractDataKey)!;
   }
   depthEventBusContainer.set(contractDataKey, new MyProposalEventBus(contractDataKey));
   return depthEventBusContainer.get(contractDataKey)!;
};
export class MyProposalEventBus implements ISmartPulseEventBus<IMyProposalEventData> {
   public currentIntradayEvent: IntradayEvent<IMyProposalEventData>;
   private contractDataKey: string;
   private _eventKey: string;
   private _subscriberCount: number = 0;

   constructor(contractDataKey: string) {
      this.contractDataKey = contractDataKey;
      this._eventKey = EventBusEnum.MY_PROPOSAL + "_" + contractDataKey;
      this.currentIntradayEvent = new IntradayEvent<IMyProposalEventData>(this._eventKey, {
         contractDataKey,
         proposals: [],
         proposalRecord: {},
      });
   }

   public subscribe(callback: (event: IntradayEvent<IMyProposalEventData>) => void) {
      BaseEventBus.subscribe(this._eventKey, callback);
      this._subscriberCount++;
   }

   public dispatch(data: IMyProposalEventData) {
      if (data.contractDataKey !== this.contractDataKey) return;
      this.currentIntradayEvent.data = data;
      BaseEventBus.dispatch(this.currentIntradayEvent);
   }

   public unSubscribe(callback: (event: IntradayEvent<IMyProposalEventData>) => void) {
      BaseEventBus.unSubscribe(this._eventKey, callback);
      this._subscriberCount--;
   }
}
