import { useCallback, useMemo } from "react";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import equal from "fast-deep-equal";
import { useIntl } from "react-intl";
import { WidgetSettingsEnum } from "src/enums/widget-settings-enum";
import { GridWidgetSettingsModel } from "src/models/grid-layout.model";
import { StoreState } from "src/old/src/redux/reducers";
import { setWorkSpaceData } from "src/old/src/redux/slice/workspaceData";
import { setUserProfileDataByKey } from "src/services/profile/profile.service";
import * as Utils from "src/utils";
import { setPreviewWidgetSettingsToLocalStorage } from "src/utils/intradayUtils";
import _ from "lodash";

interface IWidgetSettingsHook<T> {
  widgetId: string;
  key: WidgetSettingsEnum;
  defaultValue: T;
  showPopUp?: boolean;
}

const userSettingsWorkspaces = "intraday_workspaces";

const useWidgetSettings = <T>(args: IWidgetSettingsHook<T>) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  
  // Memoize args to prevent unnecessary re-renders
  const memoizedArgs = useMemo(() => args, [args.widgetId, args.key, args.defaultValue, args.showPopUp]);

  const widgetSettings = useSelector<StoreState.All, GridWidgetSettingsModel | undefined>(
    (state) => state.workspaceData.widgetRecord[memoizedArgs.widgetId]?.settings,
    equal
  );

  const isOpenPreviewSection = useSelector<StoreState.All, boolean>(
    (state) => state.workspaceData.isOpenPreviewSection,
    equal
  );
  const showPopUp = memoizedArgs.showPopUp ?? false;

  const value: T = (widgetSettings && widgetSettings[memoizedArgs.key]) ?? memoizedArgs.defaultValue;

  const debouncedSetValue = useCallback(
    debounce(async (newValue: T) => {
      if (equal(value, newValue)) return;

      if (isOpenPreviewSection) {
        setPreviewWidgetSettingsToLocalStorage(memoizedArgs.widgetId, memoizedArgs.key, newValue);
        return;
      }

      try {
        let workSpaceList = await Utils.getUserWorkSpaces();

        if (workSpaceList.length === 0) throw new Error("Workspace is not found");
        const oldWorkspaceList = _.cloneDeep(workSpaceList);

        workSpaceList = workSpaceList.map((ws) => ({
          ...ws,
          layouts: ws.layouts.map((layout) => ({
            ...layout,
            widgets: layout.widgets.map((widget) => {
              if (widget.id !== memoizedArgs.widgetId) return widget;

              if (newValue === memoizedArgs.defaultValue && widget.settings) {
                delete widget.settings[memoizedArgs.key];
                return widget;
              }

              return {
                ...widget,
                settings: {
                  ...widget.settings,
                  [memoizedArgs.key]: newValue,
                },
              };
            }),
          })),
        }));

        if (equal(oldWorkspaceList, workSpaceList)) return;

        const response = await setUserProfileDataByKey({
          Name: userSettingsWorkspaces,
          Value: JSON.stringify(workSpaceList),
        });

        if (response.isError) throw new Error(response.ErrorMessage);
        if (showPopUp) {
          Utils.showSuccessMessage(intl.formatMessage({ id: "global.warning.saved" }));
        }
        dispatch(setWorkSpaceData(workSpaceList));
      } catch (error) {
        let message = "";
        if (error instanceof Error) message = error.message;
        Utils.showErrorMessage(message);
      }
    }, 300), // 300ms debounce süresi
    [memoizedArgs.widgetId, memoizedArgs.key, memoizedArgs.defaultValue, dispatch, intl, showPopUp, isOpenPreviewSection, value]
  );



  const setValue = useCallback((newValue: T) => {
    debouncedSetValue(newValue);
  }, [debouncedSetValue]);

  return [value, setValue] as const;
};

export default useWidgetSettings;
