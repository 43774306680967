import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-balham.css";
import { AgGridReact, } from "@ag-grid-community/react";
import { Box, Dialog } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import {
    GetRowIdFunc,
    GetRowNodeIdFunc,
    GetRowIdParams,
    ColDef,
    GridReadyEvent,
} from "@ag-grid-community/core";
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState, useCallback } from "react";
import { AG_GRID_LOCALE_EN, AG_GRID_LOCALE_ES, AG_GRID_LOCALE_TR } from "@ag-grid-community/locale";
import { ErrorIcon } from "src/old/src/components/icons";
import { GridThemes } from "./GridThemes";
import useSettings from "src/hooks/useSettings.ts";
import useLocales from "src/hooks/useLocales.ts";
import { injectExtenders, } from "./Extenders/Initializer.tsx";
import useUserCulture from "src/hooks/useUserCulture.ts";
import { IGridCustomProps, IGridProps, GridApiCore } from "./Types";
import "./ag-theme-custom.css";

declare module "react" {
    function forwardRef<T, P = {}>(
        render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
    ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

function createGetRowIdProxy(getRowNodeId: GetRowNodeIdFunc): GetRowIdFunc {
    return function (params: GetRowIdParams) {
        return getRowNodeId(params.data);
    };
}

export interface WrappedGridRef<TData = any> {
    api: GridApiCore<TData> | undefined;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const WrappedGrid = forwardRef(<TData = any>(
    props: IGridProps<TData>,
    ref: React.Ref<WrappedGridRef<TData>>) => {
    const {
        onGridReady,
        getRowId,
        getContextMenuItems,
        defaultColDef,
        columnMenu,
        localeText,
        parentId,
        applyCustomStyle,

        // custom props
        height = "100%",
        width = "100%",
        defaultCulture = undefined,
        defaultTimeZone = undefined,
        useExtenders = false,

        ...others
    } = props;

    // TODO: use lodash.pick -> keyof IGridCustomProps
    const customGridOptions: IGridCustomProps = {
        height: height,
        width: width,
        defaultCulture: defaultCulture,
        defaultTimeZone: defaultTimeZone,
        useExtenders: useExtenders,
    };

    const [warningPasteDialogOpen, setWarningPasteDialogOpen] = useState<boolean>(false);
    const {formatMessage} = useIntl();
    const gridComponentRef = useRef<HTMLDivElement>(null);
    const gridRef = useRef<AgGridReact<TData>>(null);
    const prevCustomOptions = useRef<IGridCustomProps | undefined>(undefined);
    const userCulture = useUserCulture();

    const settings = useSettings();

    let theme = settings.themeMode === "dark"
        ? GridThemes.BalhamDark.toString()
        : GridThemes.Balham.toString();

    if (applyCustomStyle) {
        const customTheme = settings.themeMode === "dark"
            ? ` ${GridThemes.CustomDark.toString()}`
            : ` ${GridThemes.Custom.toString()}`;
        theme += customTheme;
    }

    const {currentLang = {value: 'tr'}} = useLocales();
    const localeTexts = currentLang.value === "tr"
        ? AG_GRID_LOCALE_TR
        : currentLang.value === "es"
            ? AG_GRID_LOCALE_ES
            : AG_GRID_LOCALE_EN;

    useImperativeHandle(ref, () => ({
        api: gridRef.current?.api,
    }));

    const getRowIdFunction: GetRowIdFunc | undefined = useMemo(() => {
        return getRowId
            ? getRowId
            : getRowId
                ? createGetRowIdProxy(getRowId)
                : undefined;
    }, [getRowId]);

    const getContextMenuItemsInternal = useCallback(() => {
        return [
            "copy",
            "copyWithHeaders",
            "copyWithGroupHeaders",
            {
                name: formatMessage({id: "ftpFileEditor.paste", defaultMessage: "Paste"}),
                action: () => {
                    setWarningPasteDialogOpen(true);
                },
                shortcut: "Ctrl V",
            },
            "separator",
            "export",
        ];
    }, [formatMessage]);

    const defaultColumnDefs = useMemo<ColDef>(
        () => ({
            menuTabs: ["generalMenuTab", "columnsMenuTab"],
            sortable: props.gridOptions?.defaultColDef?.sortable ?? false,
            resizable: props.gridOptions?.defaultColDef?.resizable ?? false,
            filter: false,
            suppressHeaderMenuButton: false,
            suppressHeaderContextMenu: false,
            suppressAutoSize: false,
            suppressColumnsToolPanel: false,
            // ag-grid in kolon bazlı veri tipi çıkarımını (https://www.ag-grid.com/react-data-grid/cell-data-types/#inferring-data-types) devre dışı bırakabilmek için kullanıldı
            // bu özellik ile ag-grid, kolon tanımlarında, davranışlarında değişiklik yapabiliyor (valueFormatter, valurParser gibi)
            cellDataType: false,
            ...defaultColDef,
        }),
        [props.gridOptions?.defaultColDef]
    );

    const onGridReadyInternal = useCallback((params: GridReadyEvent) => {
        if (gridRef.current?.api) {
            injectExtenders({
                gridRef: {
                    api: gridRef.current?.api,
                },
                customGridOptions: customGridOptions,
                prevGridOptions: undefined,
                userCultureLocale: userCulture.UserCulture,
            });
        }

        onGridReady && onGridReady(params);
    }, []);

    useEffect(() => {
        if (gridRef.current?.api) {
            injectExtenders({
                gridRef: {
                    api: gridRef.current.api,
                },
                customGridOptions: customGridOptions,
                prevGridOptions: prevCustomOptions.current,
                userCultureLocale: userCulture.UserCulture,
            });
        }

        prevCustomOptions.current = customGridOptions;
    }, [customGridOptions, userCulture.UserCulture]);

    return (
        <>
            <div
                id={parentId}
                ref={gridComponentRef}
                className={theme}
                style={{height, width}}>
                <AgGridReact<TData>
                    ref={gridRef}
                    columnMenu={"legacy"}
                    localeText={localeTexts}
                    getRowId={getRowIdFunction}
                    onGridReady={onGridReadyInternal}
                    getContextMenuItems={getContextMenuItems ??
                        props.gridOptions?.getContextMenuItems ??
                        getContextMenuItemsInternal
                    }
                    defaultColDef={defaultColumnDefs}
                    {...others}
                />
            </div>
            {warningPasteDialogOpen && (
                <Dialog open={warningPasteDialogOpen} onClose={() => setWarningPasteDialogOpen(false)}>
                    <Box
                        style={{
                            padding: "10px",
                            width: "400px",
                        }}
                    >
                        <h2 style={{fontSize: "16px", marginBottom: "5px"}}>
                            <ErrorIcon style={{float: "left", color: "blue"}}/>{" "}
                            <FormattedMessage id="global.inform" defaultMessage="Inform"/>
                        </h2>
                        <br/>
                        <p style={{fontSize: "14px", lineHeight: "1.4", textAlign: "left"}}>
                            <FormattedMessage id="global.informmessage"/>
                        </p>
                        <br/>
                        <span
                            style={{
                                float: "right",
                                color: "blue",
                                bottom: "10px",
                                right: "10px",
                                position: "absolute",
                                cursor: "pointer",
                            }}
                            onClick={() => setWarningPasteDialogOpen(false)}
                        >
                     <FormattedMessage id="global.close" defaultMessage="Close"/>
                  </span>
                    </Box>
                </Dialog>
            )}
        </>
    );
});

export default WrappedGrid;
