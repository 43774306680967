import {
  Box,
  Checkbox,
  Fab,
  FormControlLabel,
  InputBase,
  Popover,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { SaveIcon } from 'src/old/src/components/icons';
import { ICellInfo } from './types';

const ConsumptionForecastApproveSave = ({
  showPrompt,
  setShowPrompt,
  facilityTotalConsumption,
  facilityTotalConsumptionChanged,
  emptyCellsRef,
  isMultipleSave,
  handleSaveAll,
  handleSave,
  singleSaveProps,
  loadTotalForecast,
  unitNoWithProviderKey,
}: {
  emptyCellsRef: React.MutableRefObject<ICellInfo[]>;
  [key: string]: any;
}) => {
  const { formatMessage } = useIntl();

  const [anchorEl, setAnchorEl] = useState(null);
  const [totalForecasts, setTotalForecasts] = useState([]);
  const [hasForecastImport, setHasForecastImport] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldFillZero, setShouldFillZero] = useState(true);

  const getTheme = () => {
    let themeMode = 'light;';
    const settingsjson = localStorage.getItem('settings');
    if (settingsjson) {
      const settings = JSON.parse(settingsjson);
      themeMode = settings.themeMode;
    }
    return themeMode;
  };

  const decimalCalculationFormatter = (value) => {
    if (value != null && value != undefined && value != '' && value != 0) {
      return +(value?.toString().replace(',', '.') ?? 0);
    } else {
      return value;
    }
  };

  const decimalLocalizeFormatter = (value) => {
    if (value != null && value != undefined && value != '' && value != 0) {
      return value?.toString().replace('.', ',');
    } else {
      return value;
    }
  };

  useEffect(() => {
    if (showPrompt) {
      setIsLoading(true);
      handleLoadTotalForecast();
    }
  }, [showPrompt]);

  const handleLoadTotalForecast = async () => {
    var result = await loadTotalForecast();

    // find providerKey = "ForecastImport" from unitNoWithProviderKey
    const forecastImportFlag = unitNoWithProviderKey.find(
      (q) => q.providerKey === 'ForecastImport'
    );
    setHasForecastImport(forecastImportFlag ? true : false);
    setTotalForecasts(result);
    setIsLoading(false);
  };

  return (
    <Popover
      open={showPrompt}
      canAutoPosition={false}
      anchorPosition={{ top: 100, left: 50 }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      onClose={() => {
        setAnchorEl(null);
        setShowPrompt(false);
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ textAlign: 'center' }}>Tesis</TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              <FormattedMessage id="global.firsttotal" defaultMessage="First Total" />
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              <FormattedMessage id="global.updatedtotal" defaultMessage="Updated Total" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!isLoading &&
            facilityTotalConsumptionChanged.map((facility, index) => {
              let first = 0;
              if (hasForecastImport) {
                first =
                  totalForecasts
                    //@ts-ignore
                    .find((q) => q?.unitNo === facility?.facility?.id)?.totalValue ?? 0;
              } else {
                first =
                  facilityTotalConsumption
                    .find((q) => q.unitNo === facility?.facility?.id)
                    ?.forecasts?.filter((forecast) => forecast.value != null)
                    ?.map((q) => q.value)
                    .reduce((a, b) => a + b, 0) ?? 0;
              }

              let last = decimalCalculationFormatter(facility?.total ?? 0);

              if (first === last) {
                return null;
              }

              first = decimalLocalizeFormatter(
                parseFloat(decimalCalculationFormatter(first ?? 0).toFixed(1))
              );
              last = decimalLocalizeFormatter(
                parseFloat(decimalCalculationFormatter(last ?? 0).toFixed(1))
              );

              return (
                <TableRow style={{ height: '30px' }} key={index}>
                  <TableCell style={{ border: '1' }}>
                    <InputBase
                      readOnly={true}
                      //   className={classes.margin} // Add the appropriate class
                      style={{
                        width: '100%',
                        textAlign: 'center',
                        backgroundColor: getTheme() === 'dark' ? '#9A7C00' : 'yellow',
                        fontSize: '14px',
                      }}
                      defaultValue={facility?.facility?.facilityName}
                      inputProps={{
                        'aria-label': 'naked',
                        style: { textAlign: 'center' },
                      }}
                    />
                  </TableCell>
                  <TableCell style={{ border: '1' }}>
                    <InputBase
                      readOnly={true}
                      //   className={classes.margin} // Add the appropriate class
                      style={{ textAlign: 'center' }}
                      defaultValue={first}
                      inputProps={{
                        'aria-label': 'naked',
                        style: { textAlign: 'center' },
                      }}
                    />
                  </TableCell>
                  <TableCell style={{ border: '1' }}>
                    <InputBase
                      readOnly={true}
                      //   className={classes.margin} // Add the appropriate class
                      style={{ textAlign: 'center' }}
                      defaultValue={last}
                      inputProps={{
                        'aria-label': 'naked',
                        style: { textAlign: 'center' },
                      }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <Stack direction="column" gap="10px" margin="4px 10px">
        <Typography
          sx={{
            backgroundColor: getTheme() === 'dark' ? '#9A7C00' : 'yellow',
            fontSize: '14px',
            padding: '4px',
          }}
        >
          <FormattedMessage
        id={hasForecastImport ? "global.consumptionforecastchangesave" : "global.consumptionforecastsave"}
        defaultMessage={
          hasForecastImport
            ? 'Yukarıda listelenen tesisler içerisinde sadece açık kontratlarınızda şablon ile yüklenen tahminleriniz güncel tahmin olarak kayıt edilecektir. Bu şekilde kaydetmek ister misiniz?'
            : 'Yukarıdaki tesislerde değişiklik yapılmıştır. Bu şekilde kaydetmek ister misiniz?'
        }
      />
        </Typography>
        <Stack direction="row" justifyContent="flex-end" width="100%">
          {emptyCellsRef.current.length > 0 && (
            <FormControlLabel
              required
              label={formatMessage({
                id: 'forecast.savewith0filled',
                defaultMessage: 'Fill the empty cells with 0,00 to save',
              })}
              control={
                <Checkbox
                  checked={shouldFillZero}
                  onChange={(event) => setShouldFillZero(event.target.checked)}
                />
              }
              sx={{ flex: 1 }}
            />
          )}
          <Fab
            variant="extended"
            id="confirmation-save-button"
            size="small"
            color="default"
            disabled={emptyCellsRef.current.length > 0 && !shouldFillZero}
            // aria-label={<FormattedMessage id="global.save" defaultMessage="Save"/>}
            onClick={() => {
              setAnchorEl(null);
              setShowPrompt(false);
              isMultipleSave
                ? handleSaveAll(shouldFillZero ? 0 : undefined)
                : handleSave({
                    ...singleSaveProps,
                    nullSubstitute: shouldFillZero ? 0 : undefined,
                  });
            }}
          >
            <SaveIcon style={{ marginLeft: 'auto', marginRight: 'auto' }} />
          </Fab>
        </Stack>
      </Stack>
    </Popover>
  );
};

export default ConsumptionForecastApproveSave;
