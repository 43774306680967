import { defineMessages } from "react-intl";
import { IPlantPickerGroupBy } from "./components/Toolbar/PlantPicker/types";

export default Object.freeze(
  defineMessages({
    uploadforecaststarted: {
      id: "div.uploadforecaststarted",
      defaultMessage: "Uploading Forecasts has been started.",
    },
    gppexp24: {
      id: "div.gppexp24",
      defaultMessage: "No change has been made in any powerplant.",
    },
    gppexp25: {
      id: "div.gppexp25",
      defaultMessage: "Changes have been made to the above powerplants. Would you like to save it this way?",
    },
    saveWithZeroFilled: {
      id: "forecast.savewith0filled",
      defaultMessage: 'Save with "0" filled on empty cells',
    },
    uploadforecastsuccesfull: {
      id: "div.uploadforecastsuccesfull",
      defaultMessage: "Uploading Forecasts successfull",
    },
    gpexp4: {
      id: "div.gpexp4",
      defaultMessage:
        " .Registration cannot be performed without filling the empty values for the specified powerplant.",
    },
    hkspopup8exp1: {
      id: "div.hkspopup8exp1",
      defaultMessage: "Registration Successful",
    },
    hkspopup10exp1: {
      id: "div.hkspopup10exp1",
      defaultMessage: "Registration Successful For Plants Without Errors",
    },
    gpexp5: {
      id: "div.gpexp5",
      defaultMessage: " Locking has been performed.",
    },
    gpexp6: {
      id: "div.gpexp6",
      defaultMessage: " Lock operation failed for",
    },
    gpexp7: {
      id: "div.gpexp7",
      defaultMessage: " Locking process started for",
    },
    gpexp8: {
      id: "div.gpexp8",
      defaultMessage: "Are you sure all the plants will be unlocked?",
    },
    gpexp9: {
      id: "div.gpexp9",
      defaultMessage: " Unlock was performed for",
    },
    gpexp10: {
      id: "div.gpexp10",
      defaultMessage: " Unlocking failed.",
    },
    gpexp11: {
      id: "div.gpexp11",
      defaultMessage: " Unlock started.",
    },
    hour: {
      id: "global.hour",
      defaultMessage: "Hour",
    },
    prediction: {
      id: "global.prediction",
      defaultMessage: "Prediction",
    },
    gpexp12: {
      id: "div.gpexp12",
      defaultMessage: "Please enter the production estimate greater than the plant lower limit",
    },
    gpexp13: {
      id: "div.gpexp13",
      defaultMessage: "Please enter the production estimate lesser than the plant upper limit",
    },
    gpexp14: {
      id: "div.gpexp14",
      defaultMessage:
        "KW is selected as the unit. Are you sure you want to save the quantity you entered in the correct unit?",
    },
    confirmTitle: {
      id: "div.confirmTitle",
      defaultMessage: "Are you sure?",
    },
    confirm: {
      id: "div.confirm",
      defaultMessage: "Confirm",
    },
    cancel: {
      id: "global.cancel",
      defaultMessage: "Cancel",
    },
    gpexp15: {
      id: "div.gpexp15",
      defaultMessage: "The prediction information was not found.",
    },
    gpexp16: {
      id: "div.gpexp16",
      defaultMessage: "The list is loaded successfully.",
    },
    gpexp17: {
      id: "div.gpexp17",
      defaultMessage: "You do not have a uploaded file.",
    },
    pphqexp18: {
      id: "div.pphqexp18",
      defaultMessage: "The list was loaded successfully.",
    },
    lock: {
      id: "global.lock",
      defaultMessage: "Lock",
    },
    unlock: {
      id: "global.unlock",
      defaultMessage: "Unlock",
    },
    exporttoexcel: {
      id: "global.exporttoexcel",
      defaultMessage: "Export to Excel",
    },
    gpexp18: {
      id: "div.gpexp18",
      defaultMessage: "The start date cannot be greater than the end date",
    },
    maxdatadatewarning: {
      id: "div.maxdatadatewarning",
      defaultMessage: "You can upload a maximum of 1 year of data",
    },
    downloadtemplate: {
      id: "global.downloadtemplate",
      defaultMessage: "Download Template",
    },
    startdate: {
      id: "global.startdate",
      defaultMessage: "Start Date",
    },
    enddate: {
      id: "global.enddate",
      defaultMessage: "End Date",
    },
    error: {
      id: "global.error",
      defaultMessage: "Error",
    },
    saveasgopprediction: {
      id: "global.saveasgopprediction",
      defaultMessage: "Save as DAM prediction",
    },
    gpexp19: {
      id: "div.gpexp19",
      defaultMessage: "Shows the prediction time last uploaded by forecast sources",
    },
    removealllocks: {
      id: "menu.app.button.removealllocks",
      defaultMessage: "Remove all the locks",
    },
    save: {
      id: "global.save",
      defaultMessage: "Save",
    },
    filter: {
      id: "global.filter",
      defaultMessage: "Filter",
    },
    source: {
      id: "global.source",
      defaultMessage: "Source",
    },
    sources: {
      id: "global.sources",
      defaultMessage: "Sources",
    },
    unit: {
      id: "global.unit",
      defaultMessage: "Unit",
    },
    lastUpdate: {
      id: "global.lastupdate",
      defaultMessage: "Last Update",
    },
    note: {
      id: "global.note",
      defaultMessage: "Note",
    },
    total: {
      id: "global.total",
      defaultMessage: "Total",
    },
    until: {
      id: "global.until",
      defaultMessage: "Until",
    },
    powerPlantSelected: {
      id: "div.powerPlantSelected",
      defaultMessage: "Selected PowerPlant",
    },
    gpexp21: {
      id: "div.gpexp21",
      defaultMessage: "The lock date cannot be an expired date.",
    },
    all: {
      id: "global.all",
      defaultMessage: "All",
    },
    providerisnotfound: {
      id: "global.providerwasnotfound",
      defaultMessage: "Provider was not found.",
    },
    pleaseselectecompany: {
      id: "global.pleaseselectecompany",
      defaultMessage: "Please, select company",
    },
    companynotpermission: {
      id: "global.companynotpermission",
      defaultMessage: "You are not authorized for this company.",
    },
    noproviderpredictions: {
      id: "global.nohistory",
      defaultMessage: "No predictions found for the selected provider.",
    },
    ["groupby-" + IPlantPickerGroupBy.Company]: {
      id: "global.groupBy.company",
      defaultMessage: "By Company",
    },
    ["groupby-" + IPlantPickerGroupBy.PlantType]: {
      id: "global.groupBy.type",
      defaultMessage: "By Type",
    },
    ["groupby-" + IPlantPickerGroupBy.CompanyAndPlantType]: {
      id: "global.groupBy.both",
      defaultMessage: "By Company and Type",
    },
    sortbyalphabeticalorder: {
      id: "newproductionforecast.sortbyalphabetical",
      defaultMessage: "Alphabetical",
    },
    sortbyinstalledmechanicpower: {
      id: "newproductionforecast.sortbyinstalledmechanicpower",
      defaultMessage: "Installed Mechanic Power",
    },
    sortbyupdatedate: {
      id: "global.updatedate",
      defaultMessage: "Update Date",
    },
    sortbyprovider: {
      id: "newproductionforecast.sortbyprovider",
      defaultMessage: "By Provider",
    },
    sortbycompany: {
      id: "newproductionforecast.sortbycompany",
      defaultMessage: "By Company",
    },
    sortbyplanttype: {
      id: "newproductionforecast.sortbyplanttype",
      defaultMessage: "By Plant Type",
    },
    sortbyresolution: {
      id: "newproductionforecast.sortbyresolution",
      defaultMessage: "By Resolution",
    },
    oldest: {
      id: "newproductionforecast.sortitem.oldest",
      defaultMessage: "Oldest",
    },
    newest: {
      id: "newproductionforecast.sortitem.newest",
      defaultMessage: "Newest",
    },
    asc: {
      id: "newproductionforecast.sortitem.asc",
      defaultMessage: "Ascending",
    },
    desc: {
      id: "newproductionforecast.sortitem.desc",
      defaultMessage: "Descending",
    },
    belongtotab: {
      id: "newproductionforecast.sortitem.belongtotab",
      defaultMessage: "Belongs to Tab",
    },
    unitselection: {
      id: "newproductionforecast.unittype.unitselection",
      defaultMessage: "Unit Selection",
    },
    changunittypeconfirmtext: {
      id: "newproductionforecast.unittype.changunittypeconfirmtext",
      defaultMessage: "Change the unit to {unit} for this screen",
    },
    confirmsavebeforeout: {
      id: "newproductionforecast.confirmsavebeforeout",
      defaultMessage: "There are unsaved data entries, would you like to save them before continuing?",
    },
    confirmlosingallchanges: {
      id: "div.pphqexp93",
      defaultMessage: "Any unsaved changes will be lost. Do you confirm ?",
    },
    unsavedchangesdialogtitle: {
      id: "newproductionforecast.unsavedchangesdialogtitle",
      defaultMessage: "There are unsaved data entries",
    },
    hasfalsydata: {
      id: "newproductionforecast.hasfalsydata",
      defaultMessage: "There are faulty or missing data entries in the power plants listed above.",
    },
    emptycells: {
      id: "newproductionforecast.hasemptycells.title",
      defaultMessage: "There are empty cells",
    },
  })
);
