import { IGipPlanningTickerInfo } from "../../redux/api/types/gipPlanningWebSocketTypes";
import BaseEventBus, { IntradayEvent, ISmartPulseEventBus } from "../BaseEventBus";
import EventBusEnum from "../EventBusEnum";
const tickerEventBusContainer = new Map<string, TickerEventBus>();
export const getOrCreateTickerEventBus = ():TickerEventBus => {
   if(tickerEventBusContainer.has("_default")) {
       return tickerEventBusContainer.get("_default")!;
   }
   tickerEventBusContainer.set("_default", new TickerEventBus());
   return tickerEventBusContainer.get("_default")!;
};
class TickerEventBus implements ISmartPulseEventBus<IGipPlanningTickerInfo[]> {

    public currentIntradayEvent: IntradayEvent<IGipPlanningTickerInfo[]>;
    
    constructor() {
        this.currentIntradayEvent = new IntradayEvent<IGipPlanningTickerInfo[]>(EventBusEnum.TICKER, []);
    }

    public subscribe(callback: (event: IntradayEvent<IGipPlanningTickerInfo[]>) => void) {
        BaseEventBus.subscribe(EventBusEnum.TICKER, callback);
    }

    public dispatch(data: IGipPlanningTickerInfo[]) {
        this.currentIntradayEvent.data = data;
        BaseEventBus.dispatch(this.currentIntradayEvent);
    }

    public unSubscribe(callback: (event: IntradayEvent<IGipPlanningTickerInfo[]>) => void) {
        BaseEventBus.unSubscribe(EventBusEnum.TICKER, callback);
    }

}

const EventBusTicker = new TickerEventBus();

export default EventBusTicker;