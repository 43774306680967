/**
 * Shows which event names can be used on
 * @type { EventbusClass }
 */



// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface ISmartPulseEventBus<T = any> {
   currentIntradayEvent: IntradayEvent<T>;
   subscribe: (callback: (event: IntradayEvent<T>) => void) => void;
   unSubscribe: (callback: (event: IntradayEvent<T>) => void) => void;
   dispatch: (data: T) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class IntradayEvent<T = any> extends Event {
   /**
    * Create an event class with specified object type.
    */
   constructor(eventName: string, value: T) {
      super(eventName);
      this.data = value;
   }

   public data: T;
}

// interface EventBusEvents {
//    eventName: string;
//    detail: SmartEventWithDetail
// }

class EventBusClass extends EventTarget {

   public subscribe(type: string, callback) {
      this.addEventListener(type, callback);
   }

   public dispatch(event: Event) {
      this.dispatchEvent(event);
   }

   public unSubscribe(type: string, callback) {
      this.removeEventListener(type, callback);
   }
}

const BaseEventBus = new EventBusClass();

export default BaseEventBus;
