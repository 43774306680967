export enum GridThemes {
    AlpineDark = "ag-theme-alpine-dark",
    Alpine = "ag-theme-alpine",
    BalhamDark = "ag-theme-balham-dark",
    Balham = "ag-theme-balham",
    Blue = "ag-theme-blue",
    Bootstrap = "ag-theme-bootstrap",
    Dark = "ag-theme-dark",
    Fresh = "ag-theme-fresh",
    Material = "ag-theme-material",
    Custom = "ag-theme-custom",
    CustomDark = "ag-theme-custom-dark",
}