import { th } from "date-fns/locale";
import { IDepthEventBusData } from "../../redux/api/types/gipPlanningWebSocketTypes";
import BaseEventBus, { IntradayEvent, ISmartPulseEventBus } from "../BaseEventBus";
import EventBusEnum from "../EventBusEnum";
const depthEventBusContainer = new Map<string, DepthEventBus>();
export const getOrCreateDepthEventBus = (contractDataKey: string): DepthEventBus => {
   if (depthEventBusContainer.has(contractDataKey)) {
      return depthEventBusContainer.get(contractDataKey)!;
   }
   depthEventBusContainer.set(contractDataKey, new DepthEventBus(contractDataKey));
   return depthEventBusContainer.get(contractDataKey)!;
};
export class DepthEventBus implements ISmartPulseEventBus<IDepthEventBusData> {
   public currentIntradayEvent: IntradayEvent<IDepthEventBusData>;
   private contractDataKey: string;
   private _eventKey: string;
   private _subscriberCount: number = 0;

   constructor(contractDataKey: string) {
      this.contractDataKey = contractDataKey;
      this._eventKey = EventBusEnum.DEPTH + "_" + contractDataKey;
      this.currentIntradayEvent = new IntradayEvent<IDepthEventBusData>(this._eventKey, {
         contractDataKey,
         buyDepths: [],
         sellDepths: [],
      });
   }

   public subscribe(callback: (event: IntradayEvent<IDepthEventBusData>) => void) {
      BaseEventBus.subscribe(this._eventKey, callback);
      this._subscriberCount++;
   }

   public dispatch(data: IDepthEventBusData) {
      if (data.contractDataKey !== this.contractDataKey) return;
      this.currentIntradayEvent.data = data;
      BaseEventBus.dispatch(this.currentIntradayEvent);
   }

   public unSubscribe(callback: (event: IntradayEvent<IDepthEventBusData>) => void) {
      BaseEventBus.unSubscribe(this._eventKey, callback);
      this._subscriberCount--;
   }
}
