import equal from 'fast-deep-equal';
import _ from 'lodash';
import moment from 'moment';
import ReactGridLayout from 'react-grid-layout';
import { toast, ToastOptions } from 'react-toastify';
import * as CommonActions from 'src/old/src/redux/common/action';
import { ETime } from './enums/time.enum';
import { IPreviewTemplateOption, ITemplateItemInfo } from './models/gip-planning/WorkSpaceModels';
import { GridLayoutModel, GridWorkspaceModel } from './models/grid-layout.model';
import { uuidv4 } from './old/src/pages/intraday/constants';
import * as AuthService from './services/auth/auth.service';
import { getUserProfileDataByKey } from './services/profile/profile.service';
import { Constants } from './constants';
import { EWidgetType } from './enums/widget-type.enum';
import { IAlertRule, IContractShortInfo } from './old/src/redux/api/types/gipPlanningWebSocketTypes';
import { getPreviewWidgetSettingsFromLocalStorage, setNewWidgetWidth } from './utils/intradayUtils';
import { IBotGridData } from './models/gip-planning/BotsWidgetModels';

const nodeEnv = import.meta.env.MODE;
const toastConfig = {
  position: toast.POSITION.BOTTOM_RIGHT,
};

export const showSuccessMessageBottom = (message: string) => {
  toast.success(message, {
    position: toast.POSITION.BOTTOM_RIGHT,
    theme: 'colored',
  });
};

export const showErrorMessageBottom = (message: string) => {
  toast.error(message, {
    position: toast.POSITION.BOTTOM_RIGHT,
    theme: 'colored',
  });
};

export const showWarningMessageBottom = (message: string) => {
  toast.warning(message, {
    position: toast.POSITION.BOTTOM_RIGHT,
    theme: 'colored',
  });
};

export const showSuccessMessage = (message: string) => {
  toast.success(message, toastConfig);
};

export const showWarningMessage = (message: string) => {
  toast.warning(message, toastConfig);
};

export const showErrorMessage = (message: string) => {
  toast.error(message, toastConfig);
};

export const showInfoMessage = (message: string) => {
  toast.info(message, toastConfig);
};

export const showUniqueErrorMessage = (message: string, code: string) => {
  const customToastConfig = {
    position: toast.POSITION.TOP_RIGHT,
    toastId: code,
  };
  toast.error(message, customToastConfig);
};

export const showUniqueWarningMessage = (message: string, code: string) => {
  const customToastConfig = {
    position: toast.POSITION.BOTTOM_RIGHT,
    toastId: code,
  };
  toast.warning(message, customToastConfig);
};

export const showUniqueErrorMessageBottom = (message: string, code: string) => {
  toast.error(message, {
    position: toast.POSITION.BOTTOM_RIGHT,
    toastId: code,
    theme: "colored",
    hideProgressBar: true,
  })
};

export const generateUUID = () => {
  let d = new Date().getTime(),
    d2 = (performance && performance.now && performance.now() * 1000) || 0;
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let r = Math.random() * 16;
    if (d > 0) {
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c == 'x' ? r : (r & 0x7) | 0x8).toString(16);
  });
};

export const isDevelopment = () => {
  const isDevelopment = nodeEnv === "development";
  return isDevelopment;
};

export const downloadUrlConverter = (url: string) => {
  const isDevelopment = nodeEnv === "development";
  if (isDevelopment) {
    url.startsWith("/") ? url : "/" + url;
    return "/s"+ url;
  }
  return url;

};

export const isEligible = (operationId: any, type = 1) => {
  const activeUser = AuthService.getActiveUser();

  if (activeUser === undefined || activeUser === null) {
    return false;
  } else {
    const eligibilityInfo = activeUser.eligibility;
    const operations = type === 1 ? eligibilityInfo?.applications : eligibilityInfo?.functions;

    if (operations !== null && operations?.length > 0) {
      return operations.some((e) => e === operationId);
    }
    return false;
  }
};

export const hasTouchSupport = () => 'ontouchstart' in window || navigator.maxTouchPoints;

export const isMobileDevice = (beOptimistic = false) =>
  /Android|iPhone|iPad|iPod|Mobile/i.test(navigator.userAgent) ||
  (beOptimistic && hasTouchSupport());

export const isMac = () => /Mac/i.test(navigator.userAgent);
export const getOSModifierKey = () => (isMac() ? {eventProp: 'metaKey', symbol: '⌘'} : {eventProp: 'ctrlKey', symbol: 'Ctrl'});

export const getUTCDate = (date: Date) => {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
};

export const rgbToHex = (r: number, g: number, b: number) =>
  '#' +
  [r, g, b]
    .map((x) => {
      const hex = x.toString(16);
      return hex.length === 1 ? '0' + hex : hex;
    })
    .join('');

export const hexToRgba = (hex, alpha = 1) => {
  hex = hex.replace("#", "");

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const getTimeZoneOffset = () => {
  const date = new Date();
  const timezoneOffset = date.getTimezoneOffset();
  const positiveOffset = -timezoneOffset;
  const hours = Math.floor(positiveOffset / 60);
  const minutes = positiveOffset % 60;
  return timezoneOffset > 0
    ? '-'
    : '+' + (hours < 10 ? '0' : '') + hours + ':' + (minutes < 10 ? '0' : '') + minutes;
};

export function getTimeDifference(date1, date2, unit) {
  const difference = date2 - date1;

  switch (unit) {
    case ETime.Miliseconds:
      return difference;
    case ETime.Seconds:
      return Math.floor(difference / 1000);
    case ETime.Minutes:
      return Math.floor(difference / (1000 * 60));
    case ETime.Hours:
      return Math.floor(difference / (1000 * 60 * 60));
    case ETime.Days:
      return Math.floor(difference / (1000 * 60 * 60 * 24));
    case ETime.Years:
      return Math.floor(difference / (1000 * 60 * 60 * 24 * 365));
    default:
      return difference;
  }
}

export function getDateFromString(dateString) {
  if (dateString) {
    const dateParts = dateString.split(' ');
    const date = dateParts[0];
    const time = dateParts[1];
    const dateComponents = date.split('/');
    const day = parseInt(dateComponents[0]);
    const month = parseInt(dateComponents[1]) - 1;
    const year = parseInt(dateComponents[2]);
    const timeComponents = time.split(':');
    const hours = parseInt(timeComponents[0]);
    const minutes = parseInt(timeComponents[1]);
    const seconds = parseInt(timeComponents[2]);

    return new Date(year, month, day, hours, minutes, seconds);
  } else {
    return new Date();
  }
}

export function canShowColumn(columnName:string, intradayPlanningColumns:string[]) {
  if (intradayPlanningColumns?.length > 0) {
    return intradayPlanningColumns.findIndex((query) => query == columnName)>-1;
  }

  return true;
}

export function isNullOrEmpty(str: string) {
  if (str !== null && str !== undefined && str.trim() !== '') {
    return false;
  } else {
    return true;
  }
}

export const convertUtcToLocalHour = (utcDateString, locale, format = 'hh:mm') => {
  if (utcDateString == undefined) return '';

  const utcDate = new Date(
    utcDateString.replace(/(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2}):(\d{2})/, '$3-$2-$1T$4:$5:$6Z')
  );

  if (!isNaN(utcDate.getTime())) {
    const localTime = utcDate.toLocaleTimeString(locale);

    switch (format) {
      case 'hh':
        return localTime.slice(0, 2);
      case 'hh:mm':
        return localTime.slice(0, 5);
      case 'mm':
        return localTime.slice(3, 5);
      case 'ss':
        return localTime.slice(6, 8);
      case 'mm:ss':
        return localTime.slice(3, 8);
      default:
        return localTime;
    }
  } else {
    return '-';
  }
};

export const convertUtcToLocalDate = (utcDateString, locale, format = 'dd.mm.yyyy') => {
  if (utcDateString == undefined) return '';

  const utcDate = new Date(
    utcDateString.replace(/(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2}):(\d{2})/, '$3-$2-$1T$4:$5:$6Z')
  );

  if (!isNaN(utcDate.getTime())) {
    const localDate = utcDate.toLocaleDateString(locale);

    switch (format) {
      case 'dd':
        return localDate.slice(0, 2);
      case 'dd:mm':
        return localDate.slice(0, 5);
      case 'yyyy':
        return localDate.slice(6, 10);
      case 'mm':
        return localDate.slice(3, 5);
      case 'dd.mm.yyyy':
        return localDate.slice(0, 10);
      default:
        return localDate;
    }
  } else {
    return '-';
  }
};

export const getDateByTimeZone = (date: any, format = 'DD.MM.YYYY HH:mm:ss', isUtc = true) => {
  // console.log(localStorage.getItem('UserTimeZoneId'))
  const timeZone = localStorage.getItem('UserTimeZoneId') || 'Europe/Istanbul';
  return moment(date, format).utc(isUtc).tz(timeZone).toDate();
};

export const getTimeByTimeZone = (
  date: any,
  format = 'DD.MM.YYYY HH:mm:ss',
  convertFormat = 'DD.MM.YYYY HH:mm:ss ([UTC] Z)',
  isUtc = false
) => {
    const timeZone = localStorage.getItem('UserTimeZoneId') || 'Europe/Istanbul';

    let convertedTime;
    if (isUtc) {
          convertedTime = moment.utc(date, format).tz(timeZone);
    }
    else {
          convertedTime = moment(date, format).tz(timeZone);
    }
     
  return convertedTime.format(convertFormat);
};

export const convertDateToUTC = (date) => {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
};

export const getTimeByRegionTimezone=(date, format = "DD.MM.YYYY HH:mm:ss", convertFormat=("DD.MM.YYYY HH:mm:ss ([UTC] Z)"), isUtc=false)=> {

  const timeZone = CommonActions.getUserRegionTimezone();
  const convertedTime = moment(date,format).utc(isUtc).tz(timeZone);
  return convertedTime.format(convertFormat);
  
}

export const getTimeByMarketTimezone = (date: string, format = "DD.MM.YYYY HH:mm:ss", convertFormat=("DD.MM.YYYY HH:mm:ss ([UTC] Z)"), isUtc=false)=> {
  const userTimeZone = CommonActions.getUserRegionTimezone();
  const timeZone = userTimeZone == "Europe/Lisbon" ? "Europe/Madrid" : userTimeZone;
  const convertedTime = moment(date,format).utc(isUtc).tz(timeZone);
  return convertedTime.format(convertFormat);
}

export const getDateText = (dateValue: any, format: any, isUtc = false, includeSeconds = true) => {
  if (dateValue) {
    let convertionFormat = format || 'DD.MM.YYYY HH:mm:ss';
    const currentDate = new Date().getDate();

    if (includeSeconds) {
      convertionFormat = 'DD.MM.YYYY HH:mm:ss ([UTC] Z)';
    }

    if (Number(moment(dateValue, format).format('DD')) === Number(currentDate)) {
      convertionFormat = 'HH:mm:ss ([UTC] Z)';
      if (isUtc) {
        const utcmoment = moment(dateValue, format).utc(true);
        return getTimeByTimeZone(utcmoment, format, convertionFormat);
      }
      return getTimeByTimeZone(dateValue, format, convertionFormat);
    }

    if (isUtc) {
      convertionFormat = 'DD.MM.YYYY HH:mm:ss ([UTC] Z)';
      const utcmoment = moment(dateValue, format).utc(true);
      return getTimeByTimeZone(utcmoment, format, convertionFormat);
    }

    return getTimeByTimeZone(dateValue, format, convertionFormat);
  } else {
    return '';
  }
};

export const roundToDigits = (number, digits) => {
  if (number == null || number === '' || isNaN(Number(number))) {
    return null;
  }

  const factor = 10 ** digits;
  return Math.round(number * factor) / factor;
};

export const contractIdToInstrumentDate = (contractid: any) => {
  const year = '20' + contractid.substr(2, 2);
  const month = contractid.substr(4, 2);
  const day = contractid.substr(6, 2);
  const hours = contractid.substr(8, 2);
  let date = `${year}-${month}-${day}T${hours}:00:00`;
  if (hours === '24') {
    date = `${year}-${month}-${day}T00:00:00`;
    const result = moment(date, 'YYYY-MM-DDTHH:mm:ss').add(1, 'days').format('YYYY-MM-DDTHH:mm:ss');
    return result;
  } else {
    return date;
  }
};

export const tryGetDifferenceTwoObject = (
  oldData: Array<any>,
  newData: Array<any>,
  keySelector: (item: any) => any,
  redrawRowSelector?: (oldData: any, newData: any) => boolean
) => {
  const result = {
    isSame: false,
    updatedRows: [] as any,
    addRows: [] as any,
    removedRows: [] as any,
    isRequiredRedraw: false
  };

  if (equal(oldData, newData)) {
    result.isSame = true;
    return result;
  }

  const oldDataMap = new Map<any, any>();
  const newDataMap = new Map<any, any>();

  newData?.forEach((item) => {
    newDataMap.set(keySelector(item), item);
  });

  oldData?.forEach((item) => {
    oldDataMap.set(keySelector(item), item);
  });

  newDataMap?.forEach((newValue, id) => {
    const data = oldDataMap.get(id);
    if (data) {
      if (!equal(newValue, data)) {
        result.updatedRows.push(newValue);
        if (redrawRowSelector && redrawRowSelector(data, newValue)) {
          result.isRequiredRedraw = true;
        }
      }
    } else {
      result.addRows.push(newValue);
    }
  });

  oldDataMap.forEach((oldValue, id) => {
    if (!newDataMap.has(id)) {
      result.removedRows.push(oldValue);
    }
  });

  return result;
};

const DefaultTimeZone = 'Europe/Istanbul';

export const getUserTimeZone = () => {
  return localStorage.getItem('UserTimeZoneId') ?? DefaultTimeZone;
};

export const ShowLoadingOverlay = () => {
  $('#loader').addClass('whirl');
};

export const HideLoadingOverlay = () => {
  $('#loader').removeClass('whirl');
}

export const replyPropsConverter = <T extends {}>(data: T) => {
  const convertedData = Object
    .entries(data)
    .reduce((prev, [key, value]) => ({
      ...prev,
      [_.lowerFirst(key)]: value,
    }), {} as T);
  return convertedData;
};

export const tryGetNewWorkspName = (workspaceList: GridWorkspaceModel[], maximumWorkspaceLimit: number) => {
  for (let index = 1; index <= maximumWorkspaceLimit; index++) {
    const version = createWorkspName(index);
    if (!workspaceList.find(w => w.name === version))
      return version;
  }
  return createWorkspName(workspaceList.length + 1);
};

export const createWorkspName = (version: number) => `Workspace ${version}`;

export const convertTemplateToGridLayout = (items: ITemplateItemInfo[], options: IPreviewTemplateOption[]) => {
    let totalWidth = 0;

    const layout: GridLayoutModel[] = [];
    const orderMap: Map<EWidgetType, number> = new Map();
    const previewWidgetSettings = getPreviewWidgetSettingsFromLocalStorage(); 
    for (let index = 1; index <= items.length; index++) {
        const option = options.find((c) => c.boxNumber === index);
        if (!option || !option.selectedWidget) continue;
        
        const { selectedWidget, widgetOrder} = option;

        const l = new GridLayoutModel();
        l.i = uuidv4();

        const w = items[index - 1].columnSpan;
        const h = items[index - 1].rowSpan;

        l.x = items[index - 1].columnStart - 1;
        l.y = items[index - 1].rowStart - 1;
        l.w = w;
        l.h = h;
        setNewWidgetWidth(l);
        l.parentColumnSize = Constants.intraday.gridLayout.newColumnSize;

        orderMap.set(selectedWidget, (orderMap.get(selectedWidget) ?? 0) + 1);
        
        l.widgets = [
            {
                id: uuidv4(),
                isBaseWidget: true,
                order: orderMap.get(selectedWidget) ?? 1,
                widgetType: selectedWidget,
            },
        ];

        if (option.temporaryId && _.has(previewWidgetSettings, option.temporaryId))
          l.widgets[0].settings = previewWidgetSettings[option.temporaryId];
        else if (_.has(previewWidgetSettings, option.id))
          l.widgets[0].settings = previewWidgetSettings[option.id];

        l.static = false;
        l.isDraggable = false;
        l.isResizable = false;
        l.resizeHandles = ["se", "sw"]
        layout.push(l);
        
        totalWidth += w;
    }
    return layout;
};

const userSettingsWorkspaces = "intraday_workspaces";

export const getUserWorkSpaces = async() => {
  const userDataResponse = await getUserProfileDataByKey(userSettingsWorkspaces);
   
  if(userDataResponse.isError) {
     showErrorMessage(userDataResponse.ErrorMessage ?? "");
     return [] as GridWorkspaceModel[];
  }

  const workSpaces: GridWorkspaceModel[] = userDataResponse.replyObject?.Value 
     ? [...(JSON.parse(userDataResponse.replyObject?.Value))] 
     : [];

  return workSpaces;
}

export const tryDeleteWorkspace = async(workspaceId: string) => {
  const userWorkspaces = await getUserWorkSpaces();

  const newWorkspaceList = _.sortBy(
    userWorkspaces.filter(w => w.id !== workspaceId),
    (w) => moment(w.createdDate),
  );
  if (!newWorkspaceList.some((w) => w.isDefault)) {
    newWorkspaceList[newWorkspaceList.length - 1].isDefault = true;
  }

  return newWorkspaceList;
}

export const tryAddWorkSpace = async (newLayout: GridLayoutModel[], maximumWorkspaceLimit: number) => {
  const userWorkspaces = await getUserWorkSpaces();

  const newWorkSpace = new GridWorkspaceModel();
  newWorkSpace.id = uuidv4();
  newWorkSpace.isDefault = true;
  newWorkSpace.layouts = newLayout;
  newWorkSpace.name = tryGetNewWorkspName(userWorkspaces, maximumWorkspaceLimit);
  newWorkSpace.createdDate = moment().toISOString();

  const beforeDefault = userWorkspaces.find((w) => w.isDefault);
  if (beforeDefault) {
    beforeDefault.isDefault = false;
  }

  userWorkspaces.push(newWorkSpace);

  return userWorkspaces;
}

export const compareGridAndUserLayout = (gridLayout: ReactGridLayout.Layout[], userLayout: GridLayoutModel[]) => {
  const newLayout: ReactGridLayout.Layout[] = userLayout.map(x => ({
    i: x.i,
    h: x.h,
    w: x.w,
    x: x.x,
    y: x.y,
    isBounded: x.isBounded,
    isDraggable: x.isDraggable,
    isResizable: x.isResizable,
    maxH: x.maxH,
    maxW: x.maxW,
    minH: x.minH,
    minW: x.minW,
    moved: x.moved,
    resizeHandles: x.resizeHandles,
    static: x.static
  }));

  return equal(gridLayout, newLayout);
};


export const trimString = (str:string, length:number, shouldAppendDots:boolean) => {
  return str.length <= length ? str : str.substring(0, length) + (shouldAppendDots ? '...' : '');
};

export const getFirstLettersUppercase = (str) => {
  return str
    .split(' ')
    .map(word => word.charAt(0).toUpperCase())
    .join('');
};

export const calculateOrder = (widgetType: EWidgetType, userLayouts: GridLayoutModel[]) => {
  let highestOrder = 0;
  for (const layout of userLayouts) {
     for (const widget of layout.widgets) {
        if (widget.widgetType === widgetType && widget.order > highestOrder) {
           highestOrder = widget.order;
        }
     }
  }
  return highestOrder + 1;
};

export const normalise = (value: number, MIN: number, MAX: number) => _.round(((value - MIN) * 100) / (MAX - MIN));

export const getDisplayedContractName = (isOmie: boolean, isMSeven: boolean, c:IContractShortInfo) => isOmie ? c.displayName : isMSeven ? c.contractName : c.displayShortName;

export const hasAnyValue = (value: any) => !_.isNull(value) && !_.isUndefined(value);

const CLOSED_BANNER_IDS = "closedBannerIds";

export const getClosedBannerIdsFromLocalStorage = (): number[] => {
  const value = localStorage.getItem(CLOSED_BANNER_IDS);
  if (_.isNil(value)) return [];
  try {
      return JSON.parse(value) as number[];
  } catch {
      return [];
  }
}

export const setClosedBannerIdOnLocalStorage = (newValue: string) => {
  localStorage.setItem(CLOSED_BANNER_IDS, newValue);
}

export const clearClosedBannerIdsFromLocalStorage = () => localStorage.removeItem(CLOSED_BANNER_IDS);

export const roundPriceByRatio = (price, ratio, isFloor = true) => {
  let decimalPart = price % 1;
  
  if (ratio === null) {
      return null;
  }
  
  let roundedDecimalPart = isFloor ?
      Math.floor(decimalPart / ratio) * ratio : 
      Math.round(decimalPart / ratio) * ratio;
  
  let roundedPrice = Math.round((Math.trunc(price) + roundedDecimalPart) * 100) / 100;
  
  return roundedPrice;
}

export const orderAlertRules = (alertRules: IBotGridData[]) => {
   return _.orderBy(
      alertRules,
      [
         (x) => (x.hasAnyActiveListener ? 1 : 0),
         (x) => {
            if (!x.lastEditDate) return 0;
            return moment.utc(x.lastEditDate, Constants.intraday.deliveryStartFormat).valueOf();
         },
         (x) => x.botName.toLocaleUpperCase(),
      ],
      ["desc", "desc", "asc"]
   );
};

export const isValidFormattedNumber =(value: string, decimalSeparator: string, groupSeparator: string): boolean=> {
  if (groupSeparator === decimalSeparator) {
      throw new Error("Thousand separator and decimal separator cannot be the same.");
  }
  const regex = new RegExp(`^-?\\d{1,3}(${groupSeparator}\\d{3})*(\\${decimalSeparator}\\d+)?$`);
  return regex.test(value);
};