import { IRelatedGroupProposalEventData } from "../../redux/api/types/gipPlanningWebSocketTypes";
import BaseEventBus, { IntradayEvent, ISmartPulseEventBus } from "../BaseEventBus";
import EventBusEnum from "../EventBusEnum";

const depthEventBusContainer = new Map<string, RelatedGroupProposalEventBus>();
export const getOrCreateRelatedGroupEventBus = (contractDataKey: string): RelatedGroupProposalEventBus => {
   if (depthEventBusContainer.has(contractDataKey)) {
      return depthEventBusContainer.get(contractDataKey)!;
   }
   depthEventBusContainer.set(contractDataKey, new RelatedGroupProposalEventBus(contractDataKey));
   return depthEventBusContainer.get(contractDataKey)!;
};
export class RelatedGroupProposalEventBus implements ISmartPulseEventBus<IRelatedGroupProposalEventData> {
   public currentIntradayEvent: IntradayEvent<IRelatedGroupProposalEventData>;
   private contractDataKey: string;
   private _eventKey: string;
   private _subscriberCount: number = 0;

   constructor(contractDataKey: string) {
      this.contractDataKey = contractDataKey;
      this._eventKey = EventBusEnum.RELATED_GROUP_PROPOSAL + "_" + contractDataKey;
      this.currentIntradayEvent = new IntradayEvent<IRelatedGroupProposalEventData>(this._eventKey, {
         contractDataKey,
         proposals: {},
      });
   }

   public subscribe(callback: (event: IntradayEvent<IRelatedGroupProposalEventData>) => void) {
      BaseEventBus.subscribe(this._eventKey, callback);
      this._subscriberCount++;
   }

   public dispatch(data: IRelatedGroupProposalEventData) {
      if (data.contractDataKey !== this.contractDataKey) return;
      this.currentIntradayEvent.data = data;
      BaseEventBus.dispatch(this.currentIntradayEvent);
   }

   public unSubscribe(callback: (event: IntradayEvent<IRelatedGroupProposalEventData>) => void) {
      BaseEventBus.unSubscribe(this._eventKey, callback);
      this._subscriberCount--;
   }
}
