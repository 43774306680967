import { ToastContainerProps } from 'react-toastify';
import { getHostEnvironment } from './utils/hostEnvironment';

export const Constants = {
  general: {
    baseUrl: '',
  },
  requests: {
    timeout: getHostEnvironment() === 'LOCALHOST' ? 1000000 : 10000,
  },
  toastr: {
    limit: 5,
    hideProgressBar: true,
    position: 'bottom-right',
  } as ToastContainerProps,
  intraday: {
    intradayWebSocketUrl: '/api/RealtimeGip?compress=permessagedeflate',
    intradayQuarterlyWebSocketUrl: '/api/RealtimeGip?compress=permessagedeflate&enableallcontracts=1', 
    intradayPlanningSocketHandlerName: 'smartpulse/v2/intradayplanning',
    intradayPlanningPPSocketHandlerName: 'smartpulse/v2/intradaypowerplants',
    keepAliveTo: 'smartpulse/pinglistener',
    tickerTo: 'volt/alerting',
    tickerSubject: 'ticker,version-listeners',
    gipPlanningWSSubject: 'walldata',
    webSocketReopenTime: 10000,
    keepAliveSubject: 'ping',
    pingerInterval: 60000,
    intradayPlanningContractDetailSocketHandlerName: 'smartpulse/v2/intradayplanningcontract',
    selectContractSubject: 'selectcontract',
    activeContractKey: 'active',
    tradeSubject: 'volt/epias/trade',
    throttleTime: 0,
    serverDateTimeFormat: 'YYYY-MM-DDTHH:mm:ss',
    defaultNumberFormat: { minimumFractionDigits: 2, maximumFractionDigits: 2 },
    gipPlanningProductionForecastWsSubject: 'powerplantdata',
    deliveryStartFormat: 'DD/MM/YYYY HH:mm:ss',
    maximumWorkspaceLimit: 4,
    boardLessWidgetId: '51d86076-44ce-4f40-a62d-5fa5c4366a87',
    boardlessProductionPlanningWidgetId: "6c21463f-df29-410b-a969-edb9537b8241",
    defaultWidgetId: '778e1a83-eac1-4908-b37f-d2322e5d91ad',
    maximumContractSelector: 2,
    maximumOpenContractLength: 10,
    defaultWidgetLimit: 10,
    previewWidgetSettingsKey: "preview_widget_settings",
    gridLayout: {
      oldColumnSize: 12,
      newColumnSize: 192,
      userSettingsWorkspaces: "intraday_workspaces",
    }
  },
  ticker: {
    tickerItemLimit: 100,
    tickerDeleteAmount: 50,
    tickerData: [
      {
        name: 'MCP',
        message: 'intradayplanning.mcpchanged',
        targetMenu: 0,
        valueType: 'money',
        color: '#ff5733',
      },
      {
        name: 'SystemDirection',
        message: 'intradayplanning.systemdirectionchanged',
        valueType: 'money',
        targetMenu: 0,
        color: '#DAF7A6',
      },
      {
        name: 'NetPosition',
        message: 'intradayplanning.netpositionchanged',
        valueType: 'mwh',
        targetMenu: 0,
        color: '#FFC300',
      },
      {
        name: 'BiliteralAggreementQuantity',
        message: 'intradayplanning.bachanged',
        valueType: 'mwh',
        targetMenu: 0,
        color: '#FF5733',
      },
      {
        name: 'Loading',
        message: 'intradayplanning.upregulationamountchanged',
        valueType: 'mwh',
        targetMenu: 0,
        color: '#C70039',
      },
      {
        name: 'Deloading',
        message: 'intradayplanning.downregulationamountchanged',
        valueType: 'mwh',
        targetMenu: 0,
        color: '#900C3F',
      },
      {
        name: 'DayAheadDefaultQuantity',
        message: 'intradayplanning.dayaheadhourlyamountchanged',
        valueType: 'lot',
        targetMenu: 0,
        color: '#581845',
      },
      {
        name: 'DayAheadBlockQuantity',
        message: 'intradayplanning.dayaheadblockamountchanged',
        valueType: 'lot',
        targetMenu: 0,
        color: '#cb4335',
      },
      {
        name: 'IntradayDefaultQuantity',
        message: 'intradayplanning.intradayhourlyamountchanged',
        valueType: 'lot',
        targetMenu: 0,
        color: '#5499c7',
      },
      {
        name: 'IntradayBlockQuantity',
        message: 'intradayplanning.intradayblockamountchanged',
        valueType: 'lot',
        targetMenu: 0,
        color: '#52be80',
      },
      {
        name: 'ProductionPrediction',
        message: 'intradayplanning.generationforecastchanged',
        valueType: 'mwh',
        targetMenu: 0,
        color: '#85929e',
      },
      {
        name: 'ActualProduction',
        message: 'intradayplanning.realizedgenerationchanged',
        valueType: 'lot',
        targetMenu: 0,
        color: '#186a3b',
      },
      {
        name: 'RetailQuantity',
        message: 'intradayplanning.consumptionforecastchanged',
        valueType: 'mwh',
        targetMenu: 0,
        color: '#4a235a',
      },
      {
        name: 'TradeLogs',
        message: 'intradayplanning.newtransaction',
        valueType: 'text',
        targetMenu: 2,
        color: '#45b39d',
      },
      {
        name: 'BuyContractMyPrice',
        message: 'intradayplanning.mybestbuypricechanged',
        valueType: 'money',
        targetMenu: 0,
        color: '#82e0aa',
      },
      {
        name: 'BuyContractBestQuantity',
        message: 'intradayplanning.bestbidamountchanged',
        valueType: 'lot',
        targetMenu: 1,
        color: '#797d7f',
      },
      {
        name: 'BuyContractBestPrice',
        message: 'intradayplanning.bestbidpricechanged',
        valueType: 'money',
        targetMenu: 1,
        color: '#571b0e',
      },
      {
        name: 'SellContractMyPrice',
        message: 'intradayplanning.mybestsellpricechanged',
        valueType: 'money',
        targetMenu: 0,
        color: '#2f7871',
      },
      {
        name: 'SellContractBestQuantity',
        message: 'intradayplanning.bestsellamountchanged',
        valueType: 'lot',
        targetMenu: 1,
        color: '#6d3565',
      },
      {
        name: 'SellContractBestPrice',
        message: 'intradayplanning.bestsellpricechanged',
        valueType: 'money',
        targetMenu: 1,
        color: '#1b689c',
      },
      { name: 'SmartBot', message: 'smartbot.bots', targetMenu: 4, color: '#C62727' },
    ],
  },
  proposalErrorData: [
    {
      id: 'epias.hourlyproposal.error.invalidcredentials',
      defaultMessage: 'EPİAŞ kullanıcı adı veya şifresi geçersiz',
    },
    {
      id: 'epias.hourlyproposal.error.unconfirmedorganization',
      defaultMessage: 'EPİAŞ tarafında kullanıcının organizasyonu onaysızdır',
    },
    {
      id: 'epias.hourlyproposal.error.unconfirmeduser',
      defaultMessage: 'EPİAŞ tarafında kullanıcı onaysızdır',
    },
    {
      id: 'epias.hourlyproposal.error.unconfirmedparticipation',
      defaultMessage: 'EPİAŞ tarafında organizasyonun katılım onayı bulunmamaktadır',
    },
    {
      id: 'epias.hourlyproposal.error.insufficientdeposit',
      defaultMessage: 'EPİAŞ tarafında kullanıcının teminatı yetersizdir',
    },
    {
      id: 'epias.hourlyproposal.error.notauthorized',
      defaultMessage: 'EPİAŞ tarafında kullanıcının bu işlemi yapmaya yetkisi bulunmamaktadır',
    },
    {
      id: 'epias.hourlyproposal.error.adminptfdeviationlimit',
      defaultMessage:
        'Admin tarafından belirlenmiş olan PTF’ye Göre Fiyat Sapma miktarını aşamazsınız',
    },
    {
      id: 'epias.hourlyproposal.error.adminquantitylimit',
      defaultMessage:
        'Admin tarafından belirlenmiş olan maksimum ve minimum miktarları aşamazsınız',
    },
    {
      id: 'epias.hourlyproposal.error.userquantitylimit',
      defaultMessage: 'Kullanıcı teklif limitleri aşılmıştır',
    },
    {
      id: 'epias.hourlyproposal.error.limitsettingnotfound',
      defaultMessage: 'Lütfen minimum maximum miktar fiyat ayarlarınızı giriniz',
    },
  ],
  eventKeys: {
    TICKER_ADD: "TICKER_ADD"
  }
};
