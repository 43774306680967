import React from "react";
import { InputBaseComponentProps } from '@mui/material/InputBase';
import { FormatNumberOptions } from "react-intl";
import {
    NullableNumber,
    NumericTextFieldChangedEventArguments,
    NumericValueEntryOptions,
    NumericValueOptions
} from "./Types.ts";
import UserCultureFormatterProvider from "../../culture/UserCultureFormatterService.ts";
import _ from "lodash";

export const KeyCodes = {
    KEY_BACKSPACE: 'Backspace',
    KEY_DELETE: 'Delete',
    KEY_ARROW_LEFT: 'ArrowLeft',
    KEY_ARROW_RIGHT: 'ArrowRight',
    KEY_HOME: 'Home',
    KEY_END: 'End',
    KEY_TAB: 'Tab',
    KEY_F2: 'F2',
    KEY_ENTER: 'Enter',
}

export type NumericBaseElementProps<P = {}> =
    NumericValueEntryOptions &
    NumericValueOptions &
    {
        value: NullableNumber,
        formatterService: UserCultureFormatterProvider,
        onValueChanged?: (args: NumericTextFieldChangedEventArguments) => void,
    } & P;

export type NumericBaseProps<P = {}> = InputBaseComponentProps
    & { baseElementProps: NumericBaseElementProps<P>, }

// TODO: minValue, maxValue implementation
// TODO: display format implementation

export abstract class NumericBase<P extends NumericBaseProps, S>
    extends React.Component<P, S> {

    protected readonly inputRef: React.RefObject<HTMLInputElement>;

    constructor(props: NumericBaseProps & P) {
        super(props);
        this.inputRef = React.createRef<HTMLInputElement>();
    }

    protected get validInputChars(): string {
        const {
            baseElementProps: {
                allowDecimal,
                allowMinusValue,
                decimalSeparator,
                minusNumberSign,
            }
        } = this.props;
        let validInputChars: string = '1234567890';
        if (allowDecimal) validInputChars += decimalSeparator;
        if (allowMinusValue) validInputChars += minusNumberSign;
        return validInputChars;
    }

    protected get isActiveElement(): boolean {
        return Boolean(this.inputInstance) &&
            document.activeElement === this.inputInstance;
    }

    protected isKeyPressedLeftOrRight = (event: React.KeyboardEvent<HTMLInputElement>) => {
        return [KeyCodes.KEY_ARROW_LEFT, KeyCodes.KEY_ARROW_RIGHT].indexOf(event.code) > -1;
    }

    protected isKeyPressedDeleteOrBackspace(event: string): boolean;
    protected isKeyPressedDeleteOrBackspace(event: React.KeyboardEvent<HTMLInputElement>): boolean;
    protected isKeyPressedDeleteOrBackspace(event: string | React.KeyboardEvent<HTMLInputElement>): boolean {
        if (typeof event === "string") {
            return [KeyCodes.KEY_DELETE, KeyCodes.KEY_BACKSPACE].indexOf(event) > -1;
        }
        return [KeyCodes.KEY_DELETE, KeyCodes.KEY_BACKSPACE].indexOf(event.code) > -1;
    }

    protected isKeyPressedTab = (event: React.KeyboardEvent<HTMLInputElement>) => {
        return [KeyCodes.KEY_TAB].indexOf(event.code) > -1;
    }

    protected isKeyPressedHomeOrEnd = (event: React.KeyboardEvent<HTMLInputElement>) => {
        return [KeyCodes.KEY_HOME, KeyCodes.KEY_END].indexOf(event.code) > -1;
    }

    private isValidKeyPressedValue = (typedChar: string, inputCurrentValue: string): boolean => {
        if (this.isKeyPressedNegativeSign(typedChar)) {
            const { baseElementProps: { allowMinusValue } } = this.props;
            if (!allowMinusValue) {
                return false;
            } else {
                if (inputCurrentValue !== "" || inputCurrentValue.length > 0) {
                    return false;
                }
            }

            return true;
        }

        if (this.isKeyPressedDecimalSeparator(typedChar)) {
            const { baseElementProps: { allowDecimal, decimalSeparator } } = this.props;
            if (!allowDecimal) {
                return false;
            } else {
                if (inputCurrentValue.includes(decimalSeparator)) {
                    return false;
                }
            }

            return true;
        }

        if (typedChar === KeyCodes.KEY_F2) {
            const inputValue = this.onGetInputValue();
            const length = inputValue?.length ?? 0;

            if (length > 0) {
                this.inputInstance?.setSelectionRange(length, length);
            }

            return true;
        }

        if (this.validInputChars.includes(typedChar)) {
            return true;
        }

        return false;
    }

    private isKeyPressedNegativeSign = (typedChar: string): boolean => {
        const { baseElementProps: { minusNumberSign } } = this.props;
        return typedChar === minusNumberSign;
    }

    private getKeyPressed = (event: React.KeyboardEvent<HTMLInputElement>): string => {
        return event.key
            ? event.key
            : String.fromCharCode(this.getCharCodeFromEvent(event));
    }

    private getCharCodeFromEvent = (event: React.KeyboardEvent<HTMLInputElement>): number => {
        return (typeof event.which === "undefined") ? event.keyCode : event.which;
    }

    private isKeyPressedDecimalSeparator = (typedChar: string) => {
        const { baseElementProps: { decimalSeparator } } = this.props;
        return typedChar === decimalSeparator;
    }

    private getCurrentInputValue(event: React.ChangeEvent<HTMLInputElement>): string {
        if (this.inputInstance) {
            return this.inputInstance.value;
        }
        if (event.target?.name === this.constructor.name) {
            return event.target.value;
        }
        if (event.currentTarget?.name === this.constructor.name) {
            return event.currentTarget.value;
        }
        return "";
    }

    protected get inputInstance(): HTMLInputElement | null {
        return this.inputRef?.current;
    }

    //#region formatting & parsing

    protected parseToNumber = (inputValue: string): NullableNumber => {
        const { baseElementProps: { formatterService } } = this.props;
        const asNumber = formatterService.parseToNumber(inputValue);
        return isNaN(asNumber as number) ? null : asNumber;
    }

    protected formatTypedValue = (currentValue: NullableNumber): string => {
        const { baseElementProps: { formatterService } } = this.props;
        const options: FormatNumberOptions = {
            useGrouping: false,
            minimumFractionDigits: 0,
            maximumFractionDigits: 32,
        };
        return formatterService?.formatNumericValue(currentValue, options);
    }

    protected formatNumericValue = (currentValue: NullableNumber): string => {
        const { baseElementProps: { formatterService } } = this.props;
        const options: FormatNumberOptions = {
            useGrouping: true,
            minimumFractionDigits: undefined, // TODO: implement
            maximumFractionDigits: undefined // TODO: implement
        };
        return formatterService?.formatNumericValue(currentValue, options);
    }

    //#endregion

    protected abstract onGetInputValue(): string;

    protected onInputValueChanged?(event: React.ChangeEvent<HTMLInputElement>, inputValue: string): void;

    protected onFocusIn?(event: React.FocusEvent<HTMLInputElement>): void;

    protected onFocusOut?(event: React.FocusEvent<HTMLInputElement>): void;

    render(): React.ReactNode {
        const {
            baseElementProps,
            inputMode,
            onKeyDown,
            onChange,
            onFocus,
            onBlur,
            // auto injected by mui system; had to be removed because controlled by derived numeric element
            value,
            ...rest
        } = this.props;

        return (
            <input
                ref={this.inputRef}
                name={this.constructor.name}
                type={"text"}
                inputMode={"text"}
                value={this.onGetInputValue()}
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                    if (this.isKeyPressedLeftOrRight(event) ||
                        this.isKeyPressedDeleteOrBackspace(event) ||
                        this.isKeyPressedTab(event) ||
                        this.isKeyPressedHomeOrEnd(event)) {
                        event.stopPropagation();
                        return;
                    }

                    let allowKey = this.isValidKeyPressedValue(
                        this.getKeyPressed(event),
                        this.inputInstance?.value ?? ""
                    );

                    if (!allowKey) {
                        if (event.cancelable && !event.ctrlKey && event.preventDefault) {
                            event.preventDefault();
                        }
                        return;
                    }
                }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    this.onInputValueChanged
                    && this.onInputValueChanged(event, this.getCurrentInputValue(event));

                    //required for mui component rendering
                    onChange && onChange(event);
                }}
                onFocus={(event: React.FocusEvent<HTMLInputElement>) => {
                    this.onFocusIn && this.onFocusIn(event);

                    //required for mui component rendering
                    onFocus && onFocus(event)
                }}
                onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                    this.onFocusOut && this.onFocusOut(event);

                    //required for mui component rendering
                    onBlur && onBlur(event);
                }}
                {...rest}
            />
        );
    }
}
