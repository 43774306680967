import baseService from "../base.service";
import { BaseModelT, BaseReply } from "../../models/base.model";
import {
   IFacilityCompany,
   IGetCulturesResponse,
   IGetLocalFdppConfigurationResponse,
   IGetTimeZoneDefinitionsResponse,
   ISaveUserProfileRequest,
   ISetFdppNotificationsConfigurationRequest,
   IUserProfileResponse,
} from "src/models/configurations.model";
import { IUserProfileData } from "src/models/profile/IUserProfileData";
import { ILocalFdppConfiguration } from "src/pages/intradayAndFdpp/types";

export const getConsumptionForecastServiceAddress = async () => {
   try {
      const response = await baseService.get("/Configuration/GetConsumptionForecastServiceAddress");

      const result = response.data as BaseModelT<string>;

      return result.ReplyObject;
   } catch (error) {
      throw error;
   }
};

export const getUserProfile = async () => {
   const url = `Configuration/GetUserProfile`;
   const response = await baseService.post<IUserProfileResponse>(url);
   return response.data;
};

export const getTimeZoneDefinitions = async () => {
   const url = `Configuration/GetTimeZoneDefinitions`;
   const response = await baseService.post<IGetTimeZoneDefinitionsResponse>(url);
   return response.data;
};

export const getCultures = async () => {
   const url = `Configuration/GetCultures`;
   const response = await baseService.get<IGetCulturesResponse>(url);
   return response.data;
};

export const setUserSettings = async (request: IUserProfileData[]) => {
   const url = `Configuration/SetUserSettings`;
   const response = await baseService.post<BaseReply>(url, request);
   return response.data;
};

export const setUserProfile = async (request: ISaveUserProfileRequest) => {
   const url = `Configuration/SetUserProfile`;
   const response = await baseService.post<IUserProfileResponse>(url, request);
   return response.data;
};

export const getFdppConfigurations = async () => {
   const url = `Configuration/GetFdppConfigurations`;
   const response = await baseService.post<IGetLocalFdppConfigurationResponse>(url);
   return response.data;
};

export const setLocalFdppConfiguration = async (request: ILocalFdppConfiguration[]) => {
   const url = `Configuration/SetLocalFdppConfiguration`;
   const response = await baseService.post<boolean>(url, request);
   return response.data;
};

export const setFdppNotificationsConfiguration = async (request: ISetFdppNotificationsConfigurationRequest[]) => {
   const url = `Configuration/SetFdppNotificationsConfiguration`;
   const response = await baseService.post<boolean>(url, request);
   return response.data;
};

export const getFacilityConfigurations = async () => {
   const url = `Configuration/GetFacilityConfigurations`;
   const response = await baseService.post<IFacilityCompany[]>(url);
   return response.data;
};