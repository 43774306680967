import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import SearchIcon from '@mui/icons-material/Search';
import {
  AppBar,
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';

import * as consumptionForcastService from 'src/services/consumptionForcast/consumptionForecast.service';
import * as Utils from 'src/utils';

import moment from 'moment';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import SmartPulseCompanyPicker from 'src/old/src/components/smartPulse/SmartPulseCompanyPicker';
import ConsumptionForecastLockGip from './ConsumptionForecastLockGip';
import ConsumptionForecastLockGop from './ConsumptionForecastLockGop';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ConsumptionForecastLock = ({ common }) => {
  //#region Constants
  const themeStretch = true;
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const companies = common?.permissions?.companies ?? [];
  const appuserid = common?.appuserid ?? 0;
  const usergroupid = common?.permissions?.groups[0]?.id ?? 0;
  const autoC = useRef(null);

  //#endregion Constants

  //#region States
  const [selectedCompany, setSelectedCompany] = useState<any>();
  const [selectedConsumers, setSelectedConsumers] = useState<string[]>();
  const [facilityList, setFacilityList] = useState<any>([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [saveButonClickCount, setSaveButonClickCount] = useState(0);

  //#endregion States

  //#region Methods

  const getAllFacilities = async () => {
    if (!selectedCompany) return;

    const result = await consumptionForcastService.getAllCompanyFacilities(selectedCompany?.id);

    // set first facility as "Tümünü Seç" option in the list
    if (result?.data?.length > 0) {
      result?.data?.unshift({
        id: 0,
        facilityName: <FormattedMessage id="global.selectall" defaultMessage="Select All" />,
      });
    }
    setFacilityList(result?.data ?? []);
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };
  const intl = useIntl();
  const handleSave = async () => {
    if (!selectedConsumers || selectedConsumers?.length === 0) {
      const errorMessage = intl.formatMessage({ id: 'global.errormessageconsumer'});
      Utils.showErrorMessage(errorMessage);
      return;
    } else {
      setSaveButonClickCount(saveButonClickCount + 1);
    }
  };

  //#endregion Methods

  //#region useEffects

  useEffect(() => {
    getAllFacilities();
  }, [selectedCompany]);

  useEffect(() => {
    // console.log('selectedConsumers', selectedConsumers);
  }, [selectedConsumers]);

  //#endregion useEffects

  return (
    <>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <Box style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <AppBar position="static" color="default">
            <Toolbar variant="dense" style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h6" color="inherit">
                Tesis Kilit Ayarları
              </Typography>
            </Toolbar>
          </AppBar>

          <Divider />

          <Grid container spacing={2}>
            <Grid item xs={2}>
              <FormControl fullWidth={true} variant="standard">
                <SmartPulseCompanyPicker
                  style={{
                    width: '100%',
                    marginTop: '1px',
                    position: 'relative',
                    top: '1px',
                  }}
                  companies={companies}
                  onChange={(selected) => {
                    if (selected) {
                      setSelectedCompany(selected);
                      setSelectedConsumers([]);
                      //@ts-ignore
                      const close = document.getElementsByClassName(
                        'MuiAutocomplete-clearIndicator'
                      )[0];
                      //@ts-ignore
                      close?.click();
                    }
                  }}
                  allowAllCompanies={false}
                />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth={true}>
                <Autocomplete
                  ref={autoC}
                  key={'facility-list'}
                  limitTags={2}
                  multiple={true}
                  options={facilityList ?? undefined}
                  noOptionsText={
                    <FormattedMessage
                      id="global.datanotfound"
                      defaultMessage="Not found any data"
                    />
                  }
                  ListboxProps={{ style: { maxHeight: 550 } }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        //@ts-ignore
                        selectedConsumers?.length > 0 ? (
                          <FormattedMessage id="global.facilities" defaultMessage="Tesisler" />
                        ) : (
                          <FormattedMessage id="global.pick" defaultMessage="Seçiniz" />
                        )
                      }
                    />
                  )}
                  //@ts-ignore
                  getOptionLabel={(option) => option?.facilityName}
                  renderOption={(props, option) => {
                    let checked: boolean =
                      //@ts-ignore
                      option?.id === -1
                        ? true
                        : //@ts-ignore
                        selectedConsumers?.find((q) => q.id == option?.id)
                        ? true
                        : false;

                    props['aria-selected'] = checked;
                    return (
                      //@ts-ignore
                      <li {...props} key={option?.id}>
                        <Checkbox checked={checked} fontSize="small" style={{ zIndex: 1 }} />
                        {
                          //@ts-ignore
                          option?.facilityName
                        }
                      </li>
                    );
                  }}
                  renderTags={(selectedFacilities, getTagProps) => {
                    //@ts-ignore
                    if (selectedConsumers?.length < 2) {
                      return selectedConsumers?.map((e, i) => (
                        <Chip
                          {...getTagProps({ index: i })}
                          // @ts-ignore
                          label={e?.facilityName}
                          size={'small'}
                          style={{ maxWidth: '150px' }}
                          key={i}
                        />
                      ));
                    } else {
                      return [
                        <Chip
                          {...getTagProps({ index: 0 })}
                          label={
                            <FormattedMessage
                              id="global.itemsselected"
                              defaultMessage="Selected"
                              values={{ count: selectedConsumers?.length }}
                            />
                          }
                          size={'small'}
                        />,
                      ];
                    }
                  }}
                  onChange={async (event, value) => {
                    if (!value) return;
                    if (
                      value?.find(
                        (q) =>
                          //@ts-ignore
                          q?.id === -1
                      )
                    ) {
                      //@ts-ignore
                      const close = await document.getElementsByClassName(
                        'MuiAutocomplete-clearIndicator'
                      )[0];
                      //@ts-ignore
                      close?.click();

                      setSelectedConsumers([]);
                      await getAllFacilities();
                    } else if (
                      value?.find(
                        (q) =>
                          //@ts-ignore
                          q?.id === 0
                      )
                    ) {
                      setSelectedConsumers(facilityList?.filter((q) => q?.id > 0));

                      let newFacilityList = [
                        {
                          id: -1,
                          facilityName: <FormattedMessage id="global.deselectall" defaultMessage="Deselect All" />,
                        },
                      ];

                      setFacilityList(newFacilityList);
                    } else {
                      //@ts-ignore
                      setSelectedConsumers(value);
                    }
                  }}
                  sx={{
                    '& .MuiAutocomplete-clearIndicator': {
                      display: 'none',
                    },
                  }}
                ></Autocomplete>
              </FormControl>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={3}>
              <div
                style={{
                  border: '1px solid #cccccc9e',
                  padding: '3px 8px',
                  borderRadius: '8px',
                }}
              >
                <Typography>
                  {`Tarih Saat: ${moment()
                    .add(1, 'hours')
                    .startOf('hour')
                    .format('DD.MM.YYYY HH:mm')}`}
                </Typography>

                <Typography>
                  İlk Açık Kontrat: {`PH-${moment().add(1, 'hours').startOf('hour').format('HH')}`}
                </Typography>
              </div>
            </Grid>
            <Grid
              item
              xs={2}
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <TextField
                id="search-box"
                label="Arama"
                placeholder="Listede ara..."
                variant="standard"
                fullWidth={true}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton edge="start">
                        <SearchIcon htmlColor="#ccc" />
                      </IconButton>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end">
                        <CloseIcon htmlColor="#ccc" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid
              item
              xs={1}
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <IconButton style={{ width: '32px', height: '32px' }} onClick={() => handleSave()}>
                <SaveIcon htmlColor="#558118" style={{ width: '28px', height: '28px' }} />
              </IconButton>
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs
                    value={selectedTab}
                    onChange={handleChangeTab}
                    aria-label="basic tabs example"
                  >
                    <Tab label="GÖP Ayarları" {...a11yProps(0)} />
                    <Tab label="GİP Ayarları" {...a11yProps(1)} />
                  </Tabs>
                </Box>
                <TabPanel value={selectedTab} index={0}>
                  <ConsumptionForecastLockGop
                    selectedConsumers={selectedConsumers}
                    appuserid={common?.appuserid}
                    saveButonClickCount={saveButonClickCount}
                  />
                </TabPanel>
                <TabPanel value={selectedTab} index={1}>
                  <ConsumptionForecastLockGip
                    selectedConsumers={selectedConsumers}
                    appuserid={common?.appuserid}
                    saveButonClickCount={saveButonClickCount}
                  />
                </TabPanel>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default connect((state) => {
  return {
    //@ts-ignore
    common: state?.common,
  };
})(ConsumptionForecastLock);
